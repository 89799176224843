/*@import "@picocss/pico";*/

#root {
    --primary: black; /* Can create variables*/
    max-width: 1280px;
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
    color: var(--primary);
}

tr:nth-child(even) {
    background: #F2F2F2;
}

tr:nth-child(odd) {
    background: #FFF;
}

th, td {
    padding-left: 1rem;
    padding-right: 1rem;
}

span{
    background-color: white;
}
button{
    margin: 50px;
}
ul {
    list-style-type: none;
}
